import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const StyledHeader = styled.header`
  background: white;
  margin-bottom: 1.45rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const Header = ({ siteTitle }) => (
  <StyledHeader>
    <h1 style={{ margin: 0 }}>
      <Link
        to="/"
        style={{
          color: `#0000c3`,
          textDecoration: `none`,
          textAlign: "center",
          fontSize: "8vw",
        }}
      >
        {siteTitle}
      </Link>
    </h1>
    <h3
      style={{
        color: "white",
        backgroundColor: "#0000c3",
        width: "960",
        fontSize: "2vw",
      }}
    >
      ACCOUNTANTS AND BUSINESS CONSULTANTS
    </h3>
  </StyledHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
